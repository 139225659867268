import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/my-cards',
    name: 'my-cards',
    component: () => import('../views/MyCardsView.vue')
  },
  {
    path: "/my-cards/:id",
    name: "my-cards-id",
    component: () => import("../views/MyCardsIdView.vue")
  },
  {
    path: '/compatibility',
    name: 'compatibility',
    component: () => import('../views/CompatibilityView.vue')
  },
  {
    path: '/forecast',
    name: 'forecast',
    component: () => import('../views/ForecastView.vue')
  },
  {
    path: '/rectification',
    name: 'rectification',
    component: () => import('../views/RectificationView.vue')
  },
  {
    path: '/reference',
    name: 'reference',
    component: () => import('../views/ReferenceView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  },
})

export default router
