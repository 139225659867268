<template>
  <header class="header">
    <div class="header-logo">
      <img alt="Vue logo" src="../assets/logo.svg">
    </div>
    <div class="header__main">
      <div class="header__main-top">
        <div class="mobile-nav" @click="isBurger = !isBurger">
          <img alt="Burger logo" src="../assets/icons/burger.svg">
        </div>
        <RouterLink class="nav" v-for="(item, index) in links" :key="`nav${item.id}`" :to="item.link">{{ item.title }}</RouterLink>
        <RouterLink style="margin-left: auto; display: flex; align-items: center;" to="/profile">
          <img alt="User logo" src="../assets/icons/user.svg">
        </RouterLink>
        <div class="mobile-menu" v-show="isBurger">
          <RouterLink class="mobile-menu-nav" v-for="(item, index) in links" :key="`nav${item.id}`" :to="item.link">{{ item.title }}</RouterLink>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
import { ref } from 'vue';
import { RouterLink } from 'vue-router';

const isBurger = ref(false)
const links = [
  {
    id: 1,
    title: 'Главная',
    link: '/'
  },
  {
    id: 2,
    title: 'Мои карты',
    link: '/my-cards'
  },
  {
    id: 3,
    title: 'Cовместимость',
    link: '/compatibility'
  },
  {
    id: 4,
    title: 'Прогноз',
    link: '/forecast'
  },
  {
    id: 5,
    title: 'Ректификация',
    link: '/rectification'
  },
  {
    id: 6,
    title: 'Справка',
    link: '/reference'
  },
]
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  &-logo {
    width: 236px;
    min-width: 236px;
    max-width: 236px;
    img {
      width: 100%;
    }
  }
  &__main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: stretch;
    gap: 5px;
    &-top {
      background: #466581;
      border-radius: 4px;
      padding: 6px 15px 6px 17px;
      display: flex;
      align-items: center;
      box-shadow: 0px 0px 10px 0px #00000040;
      gap: 24px;
      position: relative;
      font-family: 'Inter', sans-serif;
      a {
        text-decoration: none;
        color: white;
        font-size: 14px;
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
      .mobile-nav, .mobile-menu {
        display: none;
      }
    }
  }
  @media screen and (max-width: 992px) {
    flex-direction: column;
    margin-bottom: 6px;
    &-logo {
      margin: 0 auto;
    }
    &__main {
      width: 100%;
      &-top {
        padding: 6px 12px;
      }
    }
  }

  @media screen and (max-width: 720px) {
    .nav {
      display: none;
    }
    &__main {
      &-top {
        padding: 6px 12px;
      }
    }
    .mobile-nav {
      display: flex;
      &:hover {
        cursor: pointer;
        transform: scale(1.03);
      }
    }
    .mobile-menu {
      position: absolute;
      display: flex;
      flex-direction: column;
      border-radius: 4px;
      background: white;
      left: 0;
      top: calc(100% + 1px);
      z-index: 1;
      opacity: 1;
      box-shadow: 0px 5px 30px 0px #0000004D;
      transition: all 0.2s ease;
      &-nav {
        color: #474747;
        min-height: 30px;
        display: flex;
        align-items: center;
        padding: 0 12px;
      }
    }
  }
}
</style>
